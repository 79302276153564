import React, { useRef } from "react";
import Layout from '../components/layout';
import SEO from '../components/seo';

//components
import ImageBackground from '../components/imagebackground';
import SchoolHeader from '../components/school/schoolheader';
import SchoolCard from '../components/school/schoolcard';
import Track from '../components/track';
import settings from '../../settings';
import HighlightItem from "../components/HighlightItem";
import BmwTeenSchoolGlance from "../components/bmwGlance/bmwTeenSchoolGlance";

//images
const bottomimage = `${settings.IMAGES_BASE_URL}/images/teenschool/bmw-teen-slider.jpg`;
const onedayteenschool = `${settings.IMAGES_BASE_URL}/images/teenschool/one-day-teen.jpg`;
const twodayteenschool = `${settings.IMAGES_BASE_URL}/images/teenschool/two-day-teen.jpg`;
const interstatebraking = `${settings.IMAGES_BASE_URL}/images/teenschool/interstate_braking.jpg`;
const doublelanechange = `${settings.IMAGES_BASE_URL}/images/teenschool/double-lane_change.jpg`;
const panicbraking = `${settings.IMAGES_BASE_URL}/images/teenschool/panic-braking.jpg`;
const handlingcourse = `${settings.IMAGES_BASE_URL}/images/teenschool/handling-course.jpg`;
const wetskidpad = `${settings.IMAGES_BASE_URL}/images/teenschool/wet-skid-pad.jpg`;
const teenchallenge = `${settings.IMAGES_BASE_URL}/images/teenschool/teen-challenge.jpg`;

const interstatebrakingMobile = `${settings.IMAGES_BASE_URL}/images/teenschool/interstate_braking.jpg`;
const doublelanechangeMobile = `${settings.IMAGES_BASE_URL}/images/teenschool/double-lane_change.jpg`;
const panicbrakingMobile = `${settings.IMAGES_BASE_URL}/images/teenschool/panic-braking.jpg`;
const handlingcourseMobile = `${settings.IMAGES_BASE_URL}/images/teenschool/handling-course.jpg`;
const wetskidpadMobile = `${settings.IMAGES_BASE_URL}/images/teenschool/wet-skid-pad.jpg`;
const teenchallengeMobile = `${settings.IMAGES_BASE_URL}/images/teenschool/teen-challenge.jpg`;

const topbannerDesktop = `${settings.IMAGES_BASE_URL}/images/teenschool/bmw-teen-school.jpg`;
const topbannerMobile = `${settings.IMAGES_BASE_URL}/images/teenschool/bmw-teen-school-mobile.jpg`;

const TeenSchoolPage = () =>  {
  const today = new Date()

  const contentRef = useRef(null)

  const scrollToContent= ()=>{
    contentRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
  <Layout>
    <SEO title="BMW Teen School | BMW Performance Driving School" 
      description="A BMW Driving School for teenagers, this class is geared toward learning the limits of car control on a closed course, helping teens feel safer behind the wheel while having fun." 
      keywords="teen driving school, teen driving experience, teen driving course,teen driving lessons, BMW TEEN SCHOOL"
    />

    <ImageBackground
      imageDesktop={topbannerDesktop}
      imageMobile={topbannerMobile}
      imageAlt="BMW 3 Series Teen School Top Banner"
      hideMobileHeader={true}
      topLeftChildren={
        <div className="text_left">
          <div>YOU’D BE BASIC</div>
          <div> WITHOUT THE BASICS.</div>
        </div>
      }
      scrollTo={scrollToContent}
    />
    <div className="teenschool-page">
      <section className="school"  ref={contentRef}>
        <SchoolHeader
          title="BMW TEEN SCHOOL"
          subheader="BMW DRIVING SCHOOL FOR TEENAGERS"
          subtitle="Driving should always be fun, and with a firm grasp of the basics, it can be. Our driving experience for teenagers isn’t for practicing parallel parking and taking left turns; it’s to add skills that unlock total car control at the limit and make your teen a better-equipped driver."
        />
        <SchoolCard
          image={onedayteenschool}
          imageAlt="BMW 3 Series G20 Teen 01"
          header="BMW ONE-DAY TEEN SCHOOL"
          price={ today >= new Date(2022,0,1) ? "$999" : "$849"}
          subheader="HANDS-ON LEARNING"
          text="This one-day class focuses on how a driver’s behavior affects a car. Teens will learn how to panic brake to avoid obstacles, execute emergency lane change maneuvers and in a real wake-up call, attempt to drive while distracted. In the end, students will put it all together on our Teen Challenge Course. Expect lots of laughs and some Insta-worthy moments."
          note="Please note the Summer hours for our Performance Center West location only. Classes in Thermal will begin at 6AM, June 1st through September 30th. Please check your confirmation email for details."
          monthSlider
          monthSliderHeader={"CLICK ON A MONTH BELOW TO VIEW AVAILABLE CLASSES"}
          monthSliderQuery="&class=BMW_One_Day_Teen_School"
          noteClassName="customMobile"
        />
        <SchoolCard
          image={twodayteenschool}
          imageAlt="BMW 3 Series G20 Teen 02"
          header="BMW TWO-DAY TEEN SCHOOL"
          price={ today >= new Date(2022,0,1) ? "$1,999" : "$1,699"}
          subheader="CONSIDER IT AN ADVANCED DEGREE IN DRIVING"
          monthSlider
          monthSliderHeader={"CLICK ON A MONTH BELOW TO VIEW AVAILABLE CLASSES"}
          monthSliderQuery="&class=BMW_Two_Day_Teen_School"
          note="Please note the Summer hours for our Performance Center West location only. Classes in Thermal will begin at 6AM, June 1st through September 30th. Please check your confirmation email for details."
          text="In addition to all that teens will learn on the first day of class, day two ups the ante with a Double Lane Change exercise, advanced wet skid pad training and interstate braking from high-speeds. Extracurricular activity includes a spin in an X vehicle on our off-road course, and a Performance Drive session, where teens will get to experience multiple BMWs on our track."
          noteClassName="customMobile"
        />
      </section>

      <section className="coursehiglight">
        <div className="container">
          <div className="coursehighlight__header">
            <h2>Course Highlights</h2>
            <p className="description">Our BMW Driving School for teenagers offers exhilaration behind the wheel of a BMW on our closed courses under the guidance of pro instructors.</p>
          </div>
        </div>

        <div className="container">
          <div className="highlights">
            <HighlightItem
              key="highlight-interstate-braking"
              imageDesktop={interstatebraking}
              imageMobile={interstatebrakingMobile}
              title="Interstate Braking"
              text="Students start out driving steady a 70 mph in a straight line. In their path is a box made of cones, and students must decide both at what point to hit the brakes and how much brake pressure is needed in order to stop correctly, all while keeping the car under control."
              altImage="interstate-braking"
            />

            <HighlightItem
              key="highlight-double-lane-change"
              imageDesktop={doublelanechange}
              imageMobile={doublelanechangeMobile}
              title="Double Lane Change"
              text="Students will explore how a car handles changes in direction, as their vehicle’s weight shifts through a series of left to right turns. Avoid hitting cones, and you’ll win the day."
              altImage="double-lange-change"
            />

            <HighlightItem
              key="highlight-wet-ski-pad"
              imageDesktop={wetskidpad}
              imageMobile={wetskidpadMobile}
              title="Wet Skid Pad"
              text="A polished concrete skid pad offers up the perfect spot to learn how to handle your car at the limit of grip. You’ll learn to feel when the car starts to slip and how to control it through a drift. Yea, it’s a blast."
              altImage="Wet Skid Pad"
            />

            <HighlightItem
              key="highlight-panic-braking"
              imageDesktop={panicbraking}
              imageMobile={panicbrakingMobile}
              title="Panic Braking"
              text="Learn to brake hard from speeds up to 55 mph while navigating a corner. Sounds easy: it isn’t."
              altImage="panic-braking"
            />

            <HighlightItem
              key="highlight-handling-course"
              imageDesktop={handlingcourse}
              imageMobile={handlingcourseMobile}
              title="Handling Course"
              text="Students will get an overall feel for their BMWs as they drive through our handling course. Developing a feel for the limit of traction while accelerating, braking and cornering, it’s an exercise aimed at overall car control."
              altImage="handling-course"
            />

            <HighlightItem
              key="highlight-teen-challenge"
              imageDesktop={teenchallenge}
              imageMobile={teenchallengeMobile}
              title="Teen Challenge"
              text="Combining everything learned from the days’ activities, the Teen Challenge Course combines braking, lane changes, wet and dry surfaces and proper vision into one complete exercise. Can you put it all together for the fastest lap?"
              altImage="teen-challenge"
            />
          </div>
        </div>
      </section>

      <Track variant="white" titleClassName="track__header_bold" />

      <div className="bottom-section">
        <img
          src={bottomimage}
          placeholder="Bottom Image"
          alt="BMW Teen School"
          title="BMW 3 Series Teen School Top Banner"
        />
      </div>
      <BmwTeenSchoolGlance/>
    </div>

  </Layout>
)}

export default TeenSchoolPage;
